import React from "react";

export default function Menu(props) {
  return (
    <>
      <li>
        <a href="#welcome" style={{ color: "black", textDecoration: "none" }}>
          Home
        </a>
      </li>
      <li>
        <a href="#products" style={{ color: "black", textDecoration: "none" }}>
          Products
        </a>
      </li>
      <li>
        <a href="#about" style={{ color: "black", textDecoration: "none" }}>
          Services
        </a>
      </li>
      <li>
        <a href="#trainings" style={{ color: "black", textDecoration: "none" }}>
          Trainings
        </a>
      </li>
      <li>
        <a href="#vision" style={{ color: "black", textDecoration: "none" }}>
          Projects
        </a>
      </li>
      <li>
        <a href="#books" style={{ color: "black", textDecoration: "none" }}>
          Free Books
        </a>
      </li>
      <li>
        <a href="#contact" style={{ color: "black", textDecoration: "none" }}>
          Contact
        </a>
      </li>
    </>
  );
}
