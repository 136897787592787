import React from "react";
import Background from "../../video/background1.mp4";
import logo from "../../img/topone-transparency.png";


export default function Welcome() {
  return (
    <div id="welcome">
      <div className="video-container">
        <img className="logo" alt="logo" src={logo}></img>
        <video autoPlay loop muted>
          <source src={Background} type="video/mp4" />
          <source src={Background} type="video/ogg" />
        </video>
        <div style={{ justifyContent: "center", alignItems: 'center', fontSize: "20px", display: "flex", marginTop: "750px", }}>
        <a href="#contact">
            <button style={{ backgroundColor: "#0095ff", color: "white", margin: "20px",}} className="button-container">
              Start Free Trial</button>
            <button style={{backgroundColor: "white", color: "black", margin: "20px",}} className="button-container">
              Contact Us</button>
          </a>
      </div>
      </div>
    </div>
  );
}
