import React from 'react'
// import img from '../../img/topone-transparency.png'
// import BuyCoffee from '../../img/icons/buyMeCoffee.png'

import PayPal from "../elements/PayPalTraining";
export default function Training(props) {
    // const buttonStyle = {
    //     height: 60,
    //     width: 217
    // }
    return (
        <li id='test1'>
            <img alt='logo' className='img' src={props.training.img}></img>
            <h3><a target="_black" href={props.training.link}>{props.training.title}</a></h3>
            <ul>
                {props.training.points.map(point => <li key={point}>{point}</li>)}
                <PayPal/>
            </ul>
            {/* <a rel="noreferrer" href="https://www.buymeacoffee.com/toponecrm" target="_blank">
                <img src={BuyCoffee} alt="Buy Me A Coffee" style={buttonStyle} />
            </a> */}
        </li>
    )
}