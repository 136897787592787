import React from "react";
import Button from "./Button";

export default function FreeCall(props) {
  return (
    <div id="free-call" className={`pop-upL ${props.visible ? "visible" : ""}`}>
      <div className="card-content">
        <div className="card-title">
          <h2>FREE Consultation Call - Contact@TopOne.UK</h2>
        </div>

        <div className="card-body">
          <pre
            style={{
              overflowX: "hidden",
              fontSize: "15px",
              margin: "auto",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            Looking for free consultation ?
          </pre>
          <pre
            style={{
              overflowX: "hidden",
              fontSize: "15px",
              fontFamily: "Raleway, sans-serif",
            }}
          >
            Please call us on 0333 339 2226
          </pre>
        </div>
      </div>

      <div className="btn">
        <button>
          <a href="#contact">
            <Button text="Book now" click={props.closeFreeCall} />
          </a>
        </button>
      </div>
      <span className="close" onClick={props.closeFreeCall}>
        &#x2716;
      </span>
    </div>
  );
}
