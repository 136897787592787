import crmVision from "../img/icons/crmProject.png";
import pipeline from "../img/icons/pipe.png";
import cloudsolutions from "../img/icons/cloud-solutions.png";
import experience from "../img/icons/certificate.png";
import ticket from "../img/icons/support.png";
import trainingsession from "../img/icons/training-session.png";

import fbLogo from "../img/icons/facebook-c.svg";
import instaLogo from "../img/icons/instagram-c.svg";
import twitterLogo from "../img/icons/twitter-c.svg";
import linkedinLogo from "../img/icons/linkedin-c.svg";
import youtubeLogo from "../img/icons/youtube-c.svg";
import bupa from "../img/icons/bupa.png";
import cloudsource from "../img/icons/cloud-source.png";
import cognitivegroup from "../img/icons/cognitive-group.png";
import crimson from "../img/icons/crimson.png";
import generali from "../img/icons/generali.png";
import hitachi from "../img/icons/hitachi.png";
import wealthdynamix from "../img/icons/wealth-dynamix.png";
import enfield from "../img/icons/enfield-council.jpg";

import sConsultancy from "../img/sConsultancy.png";
import sImplementation from "../img/sImplementation.png";
import sApp from "../img/TopOne_App_v3.png";
import sMarketing from "../img/V6_Marketing.png";
import sCloud from "../img/V2_Cloud.png";
import sCRM from "../img/V1_CRM_PROJECT.png";
import sDomain from "../img/V3_TopOneDomain.png";
import sSupport from "../img/sSupport.png";
import sTrademark from "../img/sTrademark.png";
import sTraining from "../img/V2_Training_.png";
import sTest from "../img/V1_Testing_Services.png";
import sDevOps from "../img/sDevOps.png";
import sData from "../img/TopOne_Data_V2.png";

import pPlatform from "../img/pPlatform.png";
import pInvestigation from "../img/Investigation_case_management..png";
import pIntegrator from "../img/pIntegrator.png";
import pCRM from "../img/V5_TopOneCRM.png";
import pCMS from "../img/pCMS.png";
import pPortal from "../img/pPortal.png";
import pLegal from "../img/pLegal.png";
import pAssetManagement from "../img/Asset_Management_.png";
import pMed from "../img/med.png"; // image banner for toponeMed
import pTopOneBuild from "../img/topone_build.png"; // image banner for topneBuild
import pTopOneGOV from "../img/topone_gov.png"; // image banner for topneGOV

//Training
import tDeveloper from "../img/IT_Developer.png"
import tConsultancy from "../img/IT_Consultant.png"
import tMarketer from "../img/IT_Marketing.png"


class Database {
  get unsubscribe() {
    return [
      {
        id: "reason",
        txt: "I do not want to tell the reason",
      },
      {
        id: "frequent",
        txt: "The newsletter are too frequent",
      },
      {
        id: "mistake",
        txt: "I never signed up for these newsletter",
      },
      {
        id: "boring",
        txt: "The content of the emails often repeats itself and gets boring",
      },
      {
        id: "to-many",
        txt: "I receive too many emails in general",
      },
      {
        id: "other",
        txt: "Other",
      },
    ];
  }

  get partners() {
    return [
      {
        id: 1,
        img: enfield,
        link: "https://toponecrm.com/",
      },

      {
        id: 2,
        img: crimson,
        link: "https://toponecrm.com/",
      },
      {
        id: 3,
        img: bupa,
        link: "https://toponecrm.com/",
      },
      {
        id: 4,
        img: cognitivegroup,
        link: "https://toponecrm.com/",
      },
      {
        id: 5,
        img: hitachi,
        link: "https://toponecrm.com/",
      },
      {
        id: 6,
        img: wealthdynamix,
        link: "https://toponecrm.com/",
      },
      {
        id: 7,
        img: generali,
        link: "https://toponecrm.com/",
      },
      {
        id: 8,
        img: cloudsource,
        link: "https://toponecrm.com/",
      },
    ];
  }

  get feedbackMsg() {
    return [
      "Did you find this site useful?",
      "What would make the site more useful?",
      "How likely would you recommend TopOne.UK",
    ];
  }

  get privacy() {
    return [
      `Cookies are very small text files that are stored on your computer when you visit a website. We use cookies for a variety of purposes and to enhance your online experience on our website (for example, to remember your account login details).
            You can change your preferences and decline certain types of cookies to be stored on your computer while browsing our website. You can also remove any cookies already stored on your computer, but keep in mind that deleting cookies may prevent you from using parts of our website.`,
      `These cookies are essential to provide you with services available through our website and to enable you to use certain features of our website.
            Without these cookies, we cannot provide you certain services on our website.`,
      `These cookies are used to provide you with a more personalized experience on our website and to remember choices you make when you use our website.
            For example, we may use functionality cookies to remember your language preferences or remember your login details.`,
      `These cookies are used to collect information to analyze the traffic to our website and how visitors are using our website.
            For example, these cookies may track things such as how long you spend on the website or the pages you visit which helps us to understand how we can improve our website site for you.
            The information collected through these tracking and performance cookies do not identify any individual visitor.
            `,
    ];
  }
  get privacyPolicy() {
    return [
      {
        id: 1,
        title: "COOKIES",
        text: [
          "TopOne.UK Site uses cookies to distinguish you from other users of our Site. This helps us to provide you with a good experience when you browse our Site and also allows us to improve our Site. Please review our Cookie Policy for more information.",
          `Cookies are very small text files that are stored on your computer when you visit a website. We use cookies for a variety of purposes and to enhance your online experience on our website (for example, to remember your account login details).
                     You can change your preferences and decline certain types of cookies to be stored on your computer while browsing our website. You can also remove any cookies already stored on your computer, but keep in mind that deleting cookies may prevent you from using parts of our website.`,
          `These cookies are essential to provide you with services available through our website and to enable you to use certain features of our website.
                      Without these cookies, we cannot provide you certain services on our website.`,
          `These cookies are used to provide you with a more personalized experience on our website and to remember choices you make when you use our website.
                        For example, we may use functionality cookies to remember your language preferences or remember your login details.`,
        ],
      },
      {
        id: 2,
        title: "INFORMATION WE MAY COLLECT",
        text: [
          "We may collect and process the following personal data about you:",
          "Information that you provide by filling in newsletter, partnership or enquiry forms on our website https://TopOne.UK viz. name, email, phone numbers, job title, organisation name.",
          "Information such as your name, email, job title, organisation name, dietary preferences shared with us by our marketing event partners or when you forward our hosted event invites to other delegates and they RSVP.",
          "Your name, email, phone numbers, job title, organisation name as shared by you through business cards, direct mail, telephonic or in-person contact.",
          "We may also collect and process personal data which is publicly available by a third party such as held on LinkedIn, Facebook, Instagram and Twitter.",
          "When you access our website https://TopOne.UK/, details of your visits to our Site (including, but not limited to, traffic data, location data, weblogs and other communication data, and the resources that you access).",
          "We may also ask you for information when you contact us for any enquiries, product demos, partnership information or other business interests. If you contact us, we may keep a record of that correspondence.",
        ],
      },
      {
        id: 3,
        title: "IP ADDRESSES",
        text: [
          "When someone visits https://TopOne.UK/ we use a third party service, Google Analytics and Click Dimensions, to collect standard internet log information and details of visitor behaviour patterns. We may collect information about your computer (or mobile device), including where available your IP address, operating system and browser type, for system administration, analytics or for our own commercial purposes.",
          "This information is only processed in a way which does not identify anyone. We do not make, and do not allow Google to make, any attempt to find out the identities of those visiting our website.",
        ],
      },
      {
        id: 4,
        title: "BACKGROUND",
        text: [
          "This privacy statement sets out the privacy policy of Sales and Marketing function of Top One UK (Trading name: TopOneCRM, TopOne, TopOneIT).",
          "TopOneUK (‘we’, ‘us’ and ‘our’) is a private limited company registered in England and Wales under Company Register Number 09707844 and with its registered office at 75 Peebles Court, 21 Whitestone Way, Croydon, CR0 4WL",
          "This notice describes how Top fulfils its obligations under applicable data protection laws, including but not limited to, Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (the General Data Protection Regulation or GDPR) effective from 25 May 2018.",
        ],
      },
      {
        id: 5,
        title: "WHERE WE STORE YOUR PERSONAL DATA",
        text: [
          "The data that we collect from you is stored on our Customer Relationship Management (CRM) system and may be transferred to, and stored at, a destination outside the European Economic Area (‘EEA’). The data may also be processed by staff operating outside of the EEA who work for us, Wealth Dynamics or for one of our suppliers. Such staff may be engaged in, among other things, the fulfillment of your order and the provision of support services. By submitting your data, you agree to this transfer, storing and/or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.",
          "We maintain strict security standards and procedures with a view to preventing unauthorised access to your data by anyone, including our staff. All our staff and third parties, whenever we hire them to provide support services, are required to observe our privacy standards and to allow us to audit them for compliance.",
        ],
      },
      {
        id: 6,
        title: "WHY WE REQUIRE THIS DATA",
        text: [
          "We require and collect the personal information about you:",
          `To provide you with information, products or services that you request from us or which we feel may interest you where you have consented to be contacted for such direct marketing purposes`,
          "To carry out our obligations arising from any contracts entered into between you and us or to manage our relationship with you and communicate.",
          "To meet our on-going regulatory and compliance obligations, including in relation to recording and monitoring communications, disclosures to tax authorities, financial service regulators and other regulatory and governmental bodies, and investigating or preventing crime;",
          "To undertake transactional and statistical analysis, and related research",
          "To ensure that content from our Site is presented in the most effective manner for you and for your computer (or mobile device). We may use your information collected from the website to personalise your repeat visits to our website.",
          "We may also use your data to provide you with information about goods and services of the Top One UK which may be of interest to you and where you have provided consent we may contact you about these by post, telephone, email or text message.",
        ],
      },
    ];
  }
  get services() {
    return [
      {
        id: 1,
        title: "TopOne Consultancy",
        img: sConsultancy,
        text: `We work closely with our clients to fully understand their business, from current processes and challenges, to future objectives and goals.`,
      },
      {
        id: 2,
        title: "TopOne Implementation",
        img: sImplementation,
        text: `We follow our proven implementation methodology for successfully delivering your project. Split into stages; Exploratory, Workstreams, Testing & Training, Go-Live and Managed Service.`,
      },
      {
        id: 3,
        title: "TopOne Training",
        img: sTraining,
        text: `Our training packages are tailored to suit the individual needs of our clients, whether that be delivering a one-to-one training session, or a group webinar. Our key aim is to ensure our clients gain the most value from their technology.`,
      },
      {
        id: 4,
        title: "TopOne Support",
        img: sSupport,
        text: `We offer a Fixed-Price Managed Service subscription, handling everything from help-desk support, training, change requests, technical consultancy and development work.`,
      },
      {
        id: 5,
        title: "TopOne CRM Project",
        img: sCRM,
        text: `Occasionally CRM projects do not go to plan and businesses are left with a system which is not driving true value and the same inefficiencies as before.`,
      },
      {
        id: 6,
        title: "TopOne Cloud Package",
        img: sCloud,
        text: `Our Quick Start packages are a cost-effective Cloud solution to get your business up and running int eh Cloud 365 as soon as possible, with the all the features you need.`,
      },
      {
        id: 7,
        title: "TopOne Marketing",
        img: sMarketing,
        text: `TopOne Marketing ensures an organization’s cases are accounted for, deployed, maintained, upgraded, and disposed of when the time comes. Put simply, it’s making sure that the valuable items, tangible and intangible, in your organization are tracked and being used.`,
      },
      {
        id: 8,
        title: "TopOne Domain",
        img: sDomain,
        text: `TopOne is licensed to sell domain names to its customers, we have a variety of domains to choose from, for more information click here!.`,
      },
      {
        id: 9,
        title: "TopOne Trademark",
        img: sTrademark,
        text: `TopOne has great experience with intellectual property. We have successfully published and registered many Trademarks, for more information click here!.`,
      },
      {
        id: 10,
        title: "TopOne Test",
        img: sTest,
        text: `Outsource with us and accelerate your digital transformation. Software testing provide an objective, independent view of the software to allow the business to appreciate and understand the risks of software implementation.`,
      },
      {
        id: 10,
        title: "TopOne DevOps",
        img: sDevOps,
        text: `Easily set up automated pipelines to build, test and deploy your code to any platform. Build, test and deploy with CI/CD that works with any language, platform and cloud. Connect to GitHub or any other Git provider and deploy continuously.`,
      },
      {
        id: 11,
        title: "TopOne Data",
        img: sData,
        text: `TopOne Data is about helping you get complete, clean, and consolidate data in real-time.`,
      },
    ];
  }
  get socialMedia() {
    return [
      {
        id: 1,
        link: "https://www.facebook.com/TopOne.UK",
        icon: fbLogo,
      },
      {
        id: 2,
        link: "https://www.instagram.com/TopOne.UK",
        icon: instaLogo,
      },
      {
        id: 3,
        link: "https://twitter.com/TopOneUK",
        icon: twitterLogo,
      },
      {
        id: 4,
        link: "https://www.linkedin.com/company/toponecrm?originalSubdomain=uk",
        icon: linkedinLogo,
      },
      {
        id: 5,
        link: "https://www.youtube.com/channel/UCUdFIcFs7Nc0WFfFJFR3-yg",
        icon: youtubeLogo,
      },
    ];
  }
  get preferences() {
    return [
      {
        type: "Your privacy",
        name: "privacy",
        allow: false,
      },
      {
        type: "Strictly necessary cookies",
        name: "necessary",
        allow: false,
      },
      {
        type: "Functionality cookies",
        name: "functionality",
        allow: false,
      },
      {
        type: "Targeting and advertising cookies",
        name: "advertising",
        allow: false,
      },
    ];
  }
  get contactInfo() {
    return {
      number: "0333 339 2226",
      email: "Contact@TopOne.UK",
      address: `Peebles Court, 
                    21 Whitestone Way,
                    Croydon CR0 4WL`,
    };
  }
  get about() {
    return {
      title: "Lorem Ipsum",
      text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer faucibus odio nec aliquet varius. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam ac tristique dolor. Donec vel est purus. Pellentesque sit amet condimentum nisl, id hendrerit nunc. Integer vel elit scelerisque, rutrum dolor sit amet, mollis massa. Fusce congue, orci vel porttitor tristique, mauris eros ornare libero, facilisis elementum enim sapien id quam. Proin quis maximus sem. Cras diam enim, fringilla et accumsan vitae, pharetra eget odio. Nulla sed diam vel nisl tempus cursus id nec felis.`,
    };
  }
  get visions() {
    return [
      {
        id: 1,
        title: "CRM SOLUTIONS",
        nr: 100,
        logo: crmVision,
      },
      {
        id: 2,
        title: "CLOUD SOLUTIONS",
        nr: 50,
        logo: cloudsolutions,
      },
      {
        id: 3,
        title: "YEARS EXPERIENCE",
        nr: 10,
        logo: experience,
      },
      {
        id: 4,
        title: "DEVOPS PROJECTS",
        nr: 1000,
        logo: pipeline,
      },
      {
        id: 5,
        title: "TRAINING SESSIONS",
        nr: 1000,
        logo: trainingsession,
      },
      {
        id: 6,
        title: "SUPPORT TICKETS",
        nr: 10000,
        logo: ticket,
      },
    ];
  }
  get trainings() {
    return [
      {
        id: 1,
        title: "IT Consultant - Microsoft Dynamics 365",
        link: "https://topone-consultant.eventbrite.co.uk",
        points: [
          `If you are considering a career in technology/the cloud as Functional Consultant and are looking to understand what Microsoft Dynamics 365/ Office 365/Azure are and how you can use it to achieve more, this is a great opportunity for you.`,
          `The event is specifically designed to provide technical knowledge in order to skill you on the Microsoft Dynamics 365 certifications which are very important for the employers.`,
          `By attending the event series, you will receive more details. It is online event on Microsoft Teams`,
        ],
        img: tConsultancy,
      },
      {
        id: 2,
        title: "IT Developer - Microsoft Dynamics 365",
        link: "https://topone-developer.eventbrite.co.uk",
        points: [
          `If you are considering a career in technology/the cloud as Developer and are looking to understand what Microsoft Dynamics 365/ Office 365 / Azure are and how you can use it to achieve more, this is a great opportunity for you.`,
          `The event is specifically designed to provide technical knowledge in order to skill you on the Microsoft Dynamics 365 certifications which are very important for the employers.`,
          `By attending the event series, you will receive more details. It is online event on Microsoft Teams`,
        ],
        img: tDeveloper,
      },
      {
        id: 3,
        title: "IT Marketing - Digital and Automated Marketing on LinkedIn",
        link: "https://www.eventbrite.co.uk/e/it-marketing-digital-and-automated-marketing-on-linkedin-tickets-124621581505",
        points: [
          `If you are looking to use the most effective marketing tools for LinkedIn and are looking to understand what marketing is, how you can use them to achieve more, this is a great opportunity for you.`,
          `The event is specifically designed to help you in grow your connections on LinkedIn, build stronger relationships, generate more revenue, all with smart automation.`,
          `By attending the event series, you will receive more details. It is online event on Microsoft Teams`,
        ],
        img: tMarketer,
      },
    ];
  }

  get books() {
    return [
      {
        id: 1,
        title: "Free Book CRM",
        link: "https://topone.uk/FreeBooksCRM",
        points: [`Free CRM Book`],
        img: pCRM,
      },
      {
        id: 2,
        title: "Free Book Security",
        link: "https://topone.uk/FreeBooksSecurity",
        points: [`Free Security Book`],
        img: pCMS,
      },
      {
        id: 3,
        title: "Free Book Apps",
        link: "https://topone.uk/FreeBooksApps",
        points: [`Free Apps Book`],
        img: sApp,
      },
    ];
  }

  get products() {
    return [
      {
        id: 1,
        title: "TopOne CRM",
        img: pCRM,
        text: `TopOne CRM is very configurable and secure system, it can be used as One Onboarding platform for Financial Services meaning it is secure and scalable.`,
      },
      {
        id: 2,
        title: "TopOne CMS",
        img: pCMS,
        text: `Case management is the process of ensuring an organization’s cases are accounted for, deployed, maintained, upgraded, tracked, and disposed of when the time comes.`,
      },
      {
        id: 3,
        title: "TopOne App",
        img: sApp,
        text: `TopOne App makes sure that the valuable items, tangible and intangible, in your organization are tracked and being used. Moreover, it provides you with the opportunity to build custom apps, drive process automation and create efficiencies for businesses of all sizes.`,
      },
      {
        id: 4,
        title: "TopOne Platform",
        img: pPlatform,
        text: `A user friendly and secure platform which consolidate everything in one place.`,
      },
      {
        id: 5,
        title: "TopOne Portal",
        img: pPortal,
        text: `TopOne Portal is a web-based platform that provides employees, customers and suppliers with a single access point to information, this system is simple, scalable and helps 
               consolidate an organization`,
      },
      {
        id: 6,
        title: "TopOne Integrator",
        img: pIntegrator,
        text: `TopOne Integrator helps deliver a highly customized system that consists of several sub components to meet specific requirements of our clients.`,
      },
      {
        id: 7,
        title: "Legal Case Management",
        img: pLegal,
        text: `Cases organized and simplified to obtain quick results.`,
      },

      {
        id: 8,
        title: "Investigation Case Management",
        img: pInvestigation,
        text: `TopOne Investigations & Case Management Software makes it easy to create and report on investigation outcomes, findings, and corrective actions.`,
      },
      {
        id: 9,
        title: "Asset Management",
        img: pAssetManagement,
        text: `Interactive system that tracks, categorises and prioritises data while giving you a tailored experience.`,
      },
      {
        id: 10,
        title: "TopOne Build",
        img: pTopOneBuild,
        text: `3D interactive environment for housing market - visualisation`,
      },
      {
        id: 11,
        title: "TopOne Med",
        // img: pAssetManagement,
        img: pMed,
        text: `Interactive website for healthcare market`,
      },
      {
        id: 12,
        title: "TopOne GOV",
        // img: pAssetManagement,
        img: pTopOneGOV,
        text: `Interactive system that tracks UK based Companies`,
      },
    ];
  }

  get learnMore() {
    return [
      {
        id: 1,
        title: "Hello Mate",
        text: ["hh"],
      },
      {
        id: 2,
        title: "INFORMATION WE MAY COLLECT",
        text: [
          "We may collect and process the following personal data about you:",
          "Information that you provide by filling in newsletter, partnership or enquiry forms on our website https://TopOne.UK viz. name, email, phone numbers, job title, organisation name.",
          "Information such as your name, email, job title, organisation name, dietary preferences shared with us by our marketing event partners or when you forward our hosted event invites to other delegates and they RSVP.",
          "Your name, email, phone numbers, job title, organisation name as shared by you through business cards, direct mail, telephonic or in-person contact.",
          "We may also collect and process personal data which is publicly available by a third party such as held on LinkedIn, Facebook, Instagram and Twitter.",
          "When you access our website https://TopOne.UK/, details of your visits to our Site (including, but not limited to, traffic data, location data, weblogs and other communication data, and the resources that you access).",
          "We may also ask you for information when you contact us for any enquiries, product demos, partnership information or other business interests. If you contact us, we may keep a record of that correspondence.",
        ],
      },
      {
        id: 3,
        title: "IP ADDRESSES",
        text: [
          "When someone visits https://TopOne.UK/ we use a third party service, Google Analytics and Click Dimensions, to collect standard internet log information and details of visitor behaviour patterns. We may collect information about your computer (or mobile device), including where available your IP address, operating system and browser type, for system administration, analytics or for our own commercial purposes.",
          "This information is only processed in a way which does not identify anyone. We do not make, and do not allow Google to make, any attempt to find out the identities of those visiting our website.",
        ],
      },
      // {
      //   id: 4,
      //   title: "BACKGROUND",
      //   text: [
      //     "This privacy statement sets out the privacy policy of Sales and Marketing function of Top One UK (Trading name: TopOneCRM, TopOne, TopOneIT).",
      //     "TopOneUK (‘we’, ‘us’ and ‘our’) is a private limited company registered in England and Wales under Company Register Number 09707844 and with its registered office at 75 Peebles Court, 21 Whitestone Way, Croydon, CR0 4WL",
      //     "This notice describes how Top fulfils its obligations under applicable data protection laws, including but not limited to, Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 (the General Data Protection Regulation or GDPR) effective from 25 May 2018.",
      //   ],
      // },
      // {
      //   id: 5,
      //   title: "WHERE WE STORE YOUR PERSONAL DATA",
      //   text: [
      //     "The data that we collect from you is stored on our Customer Relationship Management (CRM) system and may be transferred to, and stored at, a destination outside the European Economic Area (‘EEA’). The data may also be processed by staff operating outside of the EEA who work for us, Wealth Dynamics or for one of our suppliers. Such staff may be engaged in, among other things, the fulfillment of your order and the provision of support services. By submitting your data, you agree to this transfer, storing and/or processing. We will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.",
      //     "We maintain strict security standards and procedures with a view to preventing unauthorised access to your data by anyone, including our staff. All our staff and third parties, whenever we hire them to provide support services, are required to observe our privacy standards and to allow us to audit them for compliance.",
      //   ],
      // },
      // {
      //   id: 6,
      //   title: "WHY WE REQUIRE THIS DATA",
      //   text: [
      //     "We require and collect the personal information about you:",
      //     `To provide you with information, products or services that you request from us or which we feel may interest you where you have consented to be contacted for such direct marketing purposes`,
      //     "To carry out our obligations arising from any contracts entered into between you and us or to manage our relationship with you and communicate.",
      //     "To meet our on-going regulatory and compliance obligations, including in relation to recording and monitoring communications, disclosures to tax authorities, financial service regulators and other regulatory and governmental bodies, and investigating or preventing crime;",
      //     "To undertake transactional and statistical analysis, and related research",
      //     "To ensure that content from our Site is presented in the most effective manner for you and for your computer (or mobile device). We may use your information collected from the website to personalise your repeat visits to our website.",
      //     "We may also use your data to provide you with information about goods and services of the Top One UK which may be of interest to you and where you have provided consent we may contact you about these by post, telephone, email or text message.",
      //   ],
      // },
    ];
  }
}
export default new Database();
