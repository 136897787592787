import React from "react";
import { Carousel } from "react-bootstrap";

import image4 from "../../img/einstein2.jpg";
import image5 from "../../img/hawking2.jpg";
import image6 from "../../img/disney2.jpg";

import "bootstrap/dist/css/bootstrap.min.css";

const CarouselContainer = (props) => {
  return (
    <section id="vision2" className={props.show ? "visible" : "visible"}>
      <h2 className="section-title"> TopOne Values</h2>
      <div className="carouselContainer">
        <Carousel>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src={image4}
              alt="First slide"
              style={{ margin: "auto" }}
            />
            <Carousel.Caption>
              <h3 style={{ fontWeight: "bold" }}>Albert Einstein</h3>
              <p style={{}}>
                The Measure of Intelligence Is the Ability to Change
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={image5} alt="Second slide" />

            <Carousel.Caption>
              <h3 style={{ fontWeight: "bold" }}>Stephen Hawking</h3>
              <p style={{}}>Intelligence is the ability to adapt to change.</p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={image6} alt="Third slide" />

            <Carousel.Caption>
              <h3 style={{ fontWeight: "bold" }}>Walt Disney</h3>
              <p style={{}}>
                If you can visualize it, if you can dream it, there's some way
                to do it.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
};
export default CarouselContainer;
