import "./css/index.css";

import Header from "./components/layout/Header";
import About from "./components/layout/About";
import Welcome from "./components/layout/Welcome";
import Services from "./components/layout/Trainings";
import Partners from "./components/layout/Partners";
import Contact from "./components/layout/Contact";
import Menu from "./components/elements/Menu";
import CookiesToolbar from "./components/layout/CookiesToolbar";
import CookiesOptions from "./components/layout/CookiesOptions";
import Footer from "./components/layout/Footer";
import Vision from "./components/layout/Vision";
import Feedback from "./components/elements/Feedback";
import FreeCall from "./components/elements/FreeCall";
import Login from "./components/elements/Login";
import Register from "./components/elements/Register";
import PrivacyPolicy from "./components/elements/PrivacyPolicy";
import Unsubscribe from "./components/elements/Unsubscribe";
import Licensing from "./components/elements/Licensing";
import LearnMore from "./components/elements/LearnMore";

import PopUp from "./components/elements/PopUp";
import Database from "./data/Database";
import Cookies from "universal-cookie";
import React, { Component } from "react";
import Axios from "axios";
import Products from "./components/layout/Products";
import CarouselContainer from "./components/layout/CarouselContainer";
import Introduction from "./components/layout/Introduction";
import FreeBooks from "./components/elements/Books";

import "bootstrap/dist/css/bootstrap.min.css";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, addDoc, collection, getDocs } from "firebase/firestore";
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

export default class App extends Component {
  constructor(props) {
    super(props);

    // Your web app's Firebase configuration
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
      apiKey: "AIzaSyCjhl7YgCwBk403E8MKZFt_6BuaAOQOWb8",
      authDomain: "toponetest-af07e.firebaseapp.com",
      projectId: "toponetest-af07e",
      storageBucket: "toponetest-af07e.appspot.com",
      messagingSenderId: "840166344235",
      appId: "1:840166344235:web:0e640bb881d78203a90c6e",
      measurementId: "G-TZG99QX65T",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    //const analytics = getAnalytics(app);
    const auth = getAuth(app);
    const db = getFirestore(app);

    //detect auth state
    onAuthStateChanged(auth, (user) => {
      if (user != null) {
        console.log("logged in!");
      } else {
        console.log("No user");
      }
    });

    //add user to firestore
    async function addUser() {
      try {
        const docRef = await addDoc(collection(db, "users"), {
          first: "Ada",
          last: "Lovelace",
          born: 1815,
        });
        console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
      }
    }

    addUser();

    //get user from firestore
    async function getUser() {
      const querySnapshot = await getDocs(collection(db, "users"));
      querySnapshot.forEach((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
      });
    }

    getUser();

    this.state = {
      headerVisible: false,
      burgerMenu: false,
      cookies: new Cookies(),
      PORT: "https://topone-uk-prod-toponeuk-web-app.azurewebsites.net",
      cookiesOptions: false,
      cookiesBar: true,
      about: false,
      trainings: false,
      products: false,
      books: false,
      values: false,
      vision: false,
      contact: false,
      feedback: false,
      freeCall: false,
      licensing: false,
      login: false,
      register: false,
      popUp: false,
      popUpMsg: "",
      popUpConfirm: "",
      privacyPolicy: false,
      unsubscribe: false,
      learnMore: false,
    };

    this.componentDidMount = this.componentDidMount.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.toggleBurgerMenu = this.toggleBurgerMenu.bind(this);
    this.openCookiesOptions = this.openCookiesOptions.bind(this);
    this.agreeAll = this.agreeAll.bind(this);
    this.savePreferences = this.savePreferences.bind(this);
    this.closeFeedback = this.closeFeedback.bind(this);
    this.openFeedback = this.openFeedback.bind(this);
    this.closeFreeCall = this.closeFreeCall.bind(this);
    this.openFreeCall = this.openFreeCall.bind(this);
    this.closeLicensing = this.closeLicensing.bind(this);
    this.openLicensing = this.openLicensing.bind(this);
    this.closeLogin = this.closeLogin.bind(this);
    this.openLogin = this.openLogin.bind(this);
    this.closeRegister = this.closeRegister.bind(this);
    this.openRegister = this.openRegister.bind(this);
    this.closePopUp = this.closePopUp.bind(this);
    this.openPopUp = this.openPopUp.bind(this);
    this.openPrivacy = this.openPrivacy.bind(this);
    this.closePrivacy = this.closePrivacy.bind(this);
    this.openUnSub = this.openUnSub.bind(this);
    this.closeUnSub = this.closeUnSub.bind(this);
    this.registerNewUser = this.registerNewUser.bind(this);
    this.logIn = this.logIn.bind(this);
    this.signOut = this.signOut.bind(this);
    this.sendFeedback = this.sendFeedback.bind(this);
    this.openLearnMore = this.openLearnMore.bind(this);
    this.closeLearnMore = this.closeLearnMore.bind(this);
  }

  registerNewUser(user) {
    Axios.post(`${this.state.PORT}/users/insertuserUK`, { ...user })
      .then((response) => {
        this.openPopUp("Submitted");
        this.closeRegister();
        console.log(response);
      })
      .catch((err) => console.log(err));
  }
  logIn(data) {
    this.state.cookies.set("topOneLogIn", true, { path: "/" });
    this.state.cookies.set("detail", data, { path: "/" });
    console.log(this.state.cookies.getAll({ path: "/" }));
  }
  signOut() {
    this.state.cookies.set("topOneLogIn", false, { path: "/" });
    this.state.cookies.set("detail", {}, { path: "/" });
    console.log(this.state.cookies.getAll({ path: "/" }));
    this.openPopUp("You are signed out");
  }
  sendFeedback(feedback) {
    let userID;
    if (this.state.cookies.get("topOneLogIn", { path: "/" }) === "true") {
      userID = this.state.cookies.get("detail", { path: "/" }).userID;
    } else {
      userID = "anonymous";
    }
    Axios.post(`${this.state.PORT}/users/feedback`, { feedback, userID })
      .then((response) => {
        this.openPopUp("Feedback was successfully submitted", "ok");
        console.log(response);
      })
      .catch((err) => console.log(err));
  }

  handleScroll() {
    if (window.scrollY === 0) {
      this.setState({ headerVisible: false });
      return;
    } else {
      this.setState({ headerVisible: true });
    }

    let about = document.getElementById("about");
    let trainings = document.getElementById("trainings");
    let products = document.getElementById("products");
    let books = document.getElementById("books");

    let vision = document.getElementById("vision");
    let contact = document.getElementById("contact");
    let showAbout = this.isInViewport(about) ? true : false;
    let showTrainings = this.isInViewport(trainings) ? true : false;
    let showProducts = this.isInViewport(products) ? true : false;
    let showBooks = this.isInViewport(books) ? true : false;
    let showVision = this.isInViewport(vision) ? true : false;
    let showContacts = this.isInViewport(contact) ? true : false;
    this.setState({
      about: showAbout,
      trainings: showTrainings,
      products: showProducts,
      books: showBooks,
      vision: showVision,
      contact: showContacts,
    });
  }

  isInViewport = function (elem) {
    let bounding = elem.getBoundingClientRect();
    return bounding.top < 800;
  };

  toggleBurgerMenu() {
    this.setState({ burgerMenu: !this.state.burgerMenu });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
    setTimeout(() => this.openFeedback(), 15000);

    setTimeout(() => this.openFreeCall(), 10000);

    setTimeout(() => this.openLicensing(), 20000);
  }

  openCookiesOptions() {
    this.setState({ cookiesOptions: true, cookiesBar: false });
  }

  savePreferences(pref) {
    pref.forEach((element) => {
      this.state.cookies.set(element.name, element.allow, { path: "" });
    });
    this.setState({ cookiesOptions: false });
    console.log(this.state.cookies.getAll({ path: "/" }));
  }

  agreeAll() {
    Database.preferences.forEach((element) => {
      this.state.cookies.set(element.name, true, { path: "/" });
    });
    this.setState({ cookiesBar: false });
    console.log(this.state.cookies.getAll({ path: "/" }));
  }

  closeFeedback() {
    this.setState({ feedback: false });
  }
  openFeedback() {
    this.setState({ feedback: true });
  }

  closeFreeCall() {
    this.setState({ freeCall: false });
  }
  openFreeCall() {
    this.setState({ freeCall: true });
  }

  closeLicensing() {
    this.setState({ licensing: false });
  }
  openLicensing() {
    this.setState({ licensing: true });
  }

  closeLogin() {
    this.setState({ login: false });
  }
  openLogin() {
    this.setState({ login: true });
  }

  closeRegister() {
    this.setState({ register: false });
  }
  openRegister() {
    this.setState({ register: true });
  }

  closePopUp() {
    this.setState({ popUp: false, popUpMsg: "", popUpConfirm: "" });
  }
  openPopUp(msg, confirm = "close") {
    this.setState({ popUp: true, popUpMsg: msg, popUpConfirm: confirm });
  }

  openPrivacy() {
    this.setState({ privacyPolicy: true });
  }
  closePrivacy() {
    this.setState({ privacyPolicy: false });
  }

  openUnSub() {
    this.setState({ unsubscribe: true });
  }
  closeUnSub() {
    this.setState({ unsubscribe: false });
  }
  openLearnMore() {
    this.setState({ learnMore: true });
  }
  closeLearnMore() {
    this.setState({ learnMore: false });
  }

  render() {
    const {
      popUpConfirm,
      cookies,
      unsubscribe,
      privacyPolicy,
      popUp,
      popUpMsg,
      register,
      login,
      freeCall,
      licensing,
      feedback,
      headerVisible,
      cookiesBar,
      cookiesOptions,
      about,
      trainings,
      products,
      books,
      PORT,
      vision,
      contact,
      learnMore,
    } = this.state;

    return (
      <div id="main" className={`${headerVisible ? "scrolled" : ""}`}>
        <Welcome />

        {unsubscribe ? <Unsubscribe closeUnSub={this.closeUnSub} /> : ""}

        {register ? (
          <Register
            registerNewUser={this.registerNewUser}
            openPopUp={this.openPopUp}
            closeRegister={this.closeRegister}
          />
        ) : (
          ""
        )}

        {privacyPolicy ? (
          <PrivacyPolicy closePrivacy={this.closePrivacy} />
        ) : (
          ""
        )}

        {learnMore ? <LearnMore closeLearnMore={this.closeLearnMore} /> : ""}

        <Header
          signOut={this.signOut}
          cookies={cookies}
          openLogin={this.openLogin}
          toggleBurgerMenu={this.toggleBurgerMenu}
        />

        {login ? (
          <Login
            logIn={this.logIn}
            PORT={this.state.PORT}
            openPopUp={this.openPopUp}
            openRegister={this.openRegister}
            close={this.closeLogin}
          />
        ) : (
          ""
        )}

        <div
          id="burger-menu"
          className={this.state.burgerMenu ? "open" : "close"}
        >
          <ul>
            <Menu />
          </ul>
        </div>

        <Introduction />

        <Products show={products} />

        <About show={about} preferences={this.openLearnMore} />

        <CarouselContainer />

        <Services show={trainings} />

        <Vision show={vision} />

        <Partners />

        <FreeBooks show={books} />

        <Contact
          PORT={PORT}
          closePopUp={this.closePopUp}
          openPopUp={this.openPopUp}
          preferences={this.openPrivacy}
          show={contact}
        />

        {cookiesBar ? (
          <CookiesToolbar
            agree={this.agreeAll}
            preferences={this.openPrivacy}
          />
        ) : (
          ""
        )}

        {cookiesOptions ? (
          <CookiesOptions savePreferences={this.savePreferences} />
        ) : (
          ""
        )}

        <Footer openUnSub={this.openUnSub} />

        <Feedback
          sendFeedback={this.sendFeedback}
          visible={feedback ? true : false}
          closeFeedback={this.closeFeedback}
        />

        <FreeCall
          visible={freeCall ? true : false}
          closeFreeCall={this.closeFreeCall}
        />

        <Licensing
          visible={licensing ? true : false}
          closeLicensing={this.closeLicensing}
        />

        {popUp ? (
          <PopUp
            confirm={popUpConfirm}
            msg={popUpMsg}
            close={this.closePopUp}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}
