import React, { useState } from "react";
import Logo from "../../img/topone-transparency.png";
import Button from "./Button";
import Axios from "axios";
import InnerPop from "./InnerPop";
import ReCAPTCHA from "react-google-recaptcha";

export default function Login(props) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [innerPopUp, setInnerPopUp] = useState(false);
  const [innerMsg, setInnerMsg] = useState("");
  const [valid, setValid] = useState(false);

  const handleClick = () => {
    props.close();
    props.openRegister();
  };
  const handleLogIn = () => {
    if (!valid) {
      setInnerPopUp(true);
      setInnerMsg("Click on reCAPTCHA");
      console.log("ReCAPTCHA");
      return;
    }
    else if (valid){
      if (!email.includes("@") && password === "") {
        setInnerPopUp(true);
        setInnerMsg("please enter a valid email and password");
      } else if (!email.includes("@")) {
        setInnerPopUp(true);
        setInnerMsg("please enter a valid email");
      } else if (password === "") {
        setInnerPopUp(true);
        setInnerMsg("password cannot be blank");
      } else{
        Axios.get(`${props.PORT}/users/logIn`, { params: { email, password } })
        .then((resp) => {
          console.log(resp);
          if (!resp.data) {
            setInnerPopUp(true);
            setInnerMsg("Invaild login details");
          } else {
            props.openPopUp("You are logged in!");
            props.close();
            props.logIn(resp.data);
          }
        })
        .catch((err) => console.log(err));
      }
    }
  
  };

  return (
    <div id="login" className={`center-pop-up ${innerPopUp ? "msg" : ""}`}>
      <img src={Logo} alt="logo" />
      <h2>Log In</h2>
      <form>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          type="text"
          placeholder="email"
        />

        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type="password"
          placeholder="password"
        />
        <div className="ReCAPTCHA">
          <ReCAPTCHA
            sitekey="6LeeR0saAAAAAFtBWLiMVz7DdSs6kChjUtU8E-ll"
            onChange={() => setValid(true)}
          />
        </div>
        <div className="container">
          <Button click={handleLogIn} text="Log in" />
          <InnerPop msg={innerMsg} />
          <Button click={handleClick} text="Register" />
        </div>
      </form>
      <span onClick={props.close} className="close">
        &#x2716;
      </span>
      {/* <InnerPop msg={'wrong detail'} /> */}
    </div>
  );
}
