import React, { useState } from "react";
import Menu from "../elements/Menu";
import Data from "../../data/Database";
import { Modal } from "react-bootstrap";
import PayPal from "../elements/PayPalPayement";

import Logo from "../../img/topone-social media.PNG"

export default function Header(props) {
  const [burger, toggleBurger] = useState(false);

  const handleClick = () => {
    toggleBurger(!burger);
    props.toggleBurgerMenu();
    console.log("bam");
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [paymentPayPal, setPaymentPayPal] = useState(20);

  return (
    <div id="header">
      <ul className="contact-us-info">
        <li className="number">{Data.contactInfo.number}</li>
        <li>
          <a
            href={`mailto: ${Data.contactInfo.email}`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {Data.contactInfo.email}
          </a>
        </li>
        <li className="could-button">
          <a href="#contact">
            <button>Demo Request</button>
          </a>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </li>
        <li className="could-button">
          <button onClick={handleShow}>Payment</button>
          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header
              closeButton
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Modal.Title>
                TopOne Payment
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form className="register-form">
                <img src={Logo} alt="logo" className="paymentLogo" />
                <label style={{ fontSize: "25px", marginBottom: "50px" }}>
                  Enter value you would like to pay £
                  <input
                    id="paymentPayPal"
                    name="PayPalPayments"
                    className="form-field"
                    type="numbers"
                    required
                    value={paymentPayPal}
                    onChange={(e) => setPaymentPayPal(e.target.value)}
                  />
                </label>
              </form>
              <PayPal />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </li>
        {props.cookies.get("topOneLogIn", { path: "/" }) === "true" ? (
          <li className="could-button">
            <button onClick={props.signOut}>Sign out</button>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </li>
        ) : (
          <li className="could-button">
            <button onClick={props.openLogin}>Log in</button>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </li>
        )}
        {Data.socialMedia.map((item) => {
          return (
            <li key={item.id}>
              <a href={item.link}>
                <img src={item.icon} alt="icon"></img>
              </a>
            </li>
          );
        })}
      </ul>
      <ul className="header-menu">
        <Menu />
        <li className="translate">
          <div id="google_translate_element"></div>
        </li>
      </ul>
      <div
        id="burger-button"
        className={burger ? "open" : "close"}
        onClick={() => handleClick()}
      >
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
}
