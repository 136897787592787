import React from "react";
import Address from "../elements/Address";
import logo from "../../img/topone-transparency.png";
import cyberLogo from "../../img/cyber-essentials-plus-badge-high-res.png";
import mic from "../../img/mic.png";
import Data from "../../data/Database";

export default function Footer(props) {
  const frameStyle = {
    width: "100%",
    maxWidth: 2000,
    height: 380,
    frameborder: 0,
  };

  return (
    <section id="footer">
      <div className="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2490.5695124802687!2d-0.11585600955064258!3d51.37421021192745!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487600d277daa58d%3A0xee327ea093c166da!2sStart%20Up%20Croydon!5e0!3m2!1sen!2suk!4v1581757863101!5m2!1sen!2suk"
          style={frameStyle}
        ></iframe>
      </div>
      <div className="container">
        <ul>
          <li>
            <h4>Address</h4>
          </li>
          <li>
            <Address />
          </li>
        </ul>
        <ul>
          <li>
            <h4>Training</h4>
          </li>
          <li>IT Consultant</li>
          <li>IT Developer</li>
          <li>IT Marketing</li>
        </ul>
        <ul>
          <li>
            <img className="logo" alt="logo" src={logo}></img>
            <img className="logo" alt="cLogo" src={cyberLogo} style={{marginTop: "50px"}}></img>
          </li>
        </ul>
        <ul>
          <li>
            <h4>Services</h4>
          </li>
          <li>
            {Data.services.map((item) => {
              return (
                <div key={item.id}>
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href={"https://topone.uk/" + item.title.replace(/\s+/g, "")}
                  >
                    <p style={{ color: "white", textDecoration: "none" }}>
                      {item.title.replace("TopOne", "")}
                    </p>
                  </a>
                </div>
              );
            })}
          </li>
        </ul>
        <ul>
          <li>
            <h4>Products</h4>
          </li>
          <li>
            {Data.products.map((item) => {
              return (
                <div key={item.id}>
                  <a
                    style={{ color: "white", textDecoration: "none" }}
                    href={"https://topone.uk/" + item.title.replace(/\s+/g, "")}
                  >
                    <p>{item.title.replace("TopOne", "")}</p>
                  </a>
                </div>
              );
            })}
          </li>
        </ul>
        <ul>
          <li>
            <img alt="microsoft" src={mic}></img>
          </li>
        </ul>
        <ul>
          <li>
            <h4>Follow us</h4>
          </li>
          <li className="social-container">
            {Data.socialMedia.map((item) => {
              return (
                <div key={item.id}>
                  <a href={item.link}>
                    <img alt="log" src={item.icon}></img>
                  </a>
                </div>
              );
            })}
          </li>
        </ul>
        <ul>
          <li>
            <h4>TopOne</h4>
          </li>
          <li>Blog</li>
          <li>
            {" "}
            <a href="https://trademarks.ipo.gov.uk/ipo-tmcase/page/Results/1/UK00003583804">
              Trademark
            </a>
          </li>
          <a
            href={"https://topone.uk/unsubscribe"}
            style={{ textDecoration: "none" }}
          >
            <li style={{ color: "white" }}>Unsubscribe</li>
          </a>
        </ul>
      </div>
    </section>
  );
}
