import React from "react";
import Data from "../../data/Database";
import { Card } from "react-bootstrap";

import "./../../css/card-style.css";

export default function Books(props) {
  return (
    <section id="books" className={props.show ? "visible" : "hide"}>
      <h2 className="section-title">TopOne Free Books</h2>
      <div className="container">
        {Data.books.map((item) => {
          return (
            <div key={item.id} className="item">
              <div className="card text-center">
                <div className="overflow">
                  <Card className="trainingCardStyle">
                    <Card.Img variant="top" src={item.img} />
                    <Card.Body>
                      <Card.Title>
                        <h4
                          style={{
                            color: "black",
                            textDecoration: "none",
                            fontWeight: "bolder",
                          }}
                        >
                          {item.title}
                        </h4>
                      </Card.Title>
                      <Card.Text
                        className="cardText"
                        style={{
                          color: "black",
                          textDecoration: "none",
                        }}
                      >
                        {item.points}
                      </Card.Text>
                      <a
                        style={{
                          color: "black",
                          textDecoration: "none",
                        }}
                        href={
                          item.link
                        }
                      >
                        <button
                          style={{
                            justifyContent: "center",
                            fontSize: "20px",
                          }}
                          className="button-container"
                          onClick={() => this.getModal(item.id)}
                        >
                          {item.title}
                        </button>
                      </a>
                    </Card.Body>
                  </Card>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
}
