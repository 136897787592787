import React from "react";
import Data from "../../data/Database";
import Button from "./Button";

export default function LearnMore(props) {
  let serviceList = Data.learnMore.map((item, i) => {
    return (
      <div key={i}>
        <p>{item.text}</p>
      </div>
    );
  });

  return (
    <div id="learn-more">
      <h2>TopOne Learn More</h2>
      <ul className="list"></ul>
      <div className="policy">
        <p>{serviceList}</p>
      </div>
      <Button click={props.closeLearnMore} text="close" />
    </div>
  );
}
