import React from "react";

export default function Recaptcha() {
  return (
    <div>
      <div
        className="g-recaptcha"
        data-sitekey="6LeeR0saAAAAAFtBWLiMVz7DdSs6kChjUtU8E-ll"
        style={{ textAlign:"center" }}
      ></div>
    </div>
  );
}
